import React from 'react'
import { View, styled } from 'dripsy'
import { Image as Img } from 'react-native'

const Image = styled(Img)({})

type Props = {
  size?: number | number[]
  shouldShowLarge?: boolean[]
  containerSx?: React.ComponentProps<typeof View>['sx']
} & React.ComponentProps<typeof View>

const Logo = React.memo(function Logo(props: Props) {
  const {
    sx = {},
    size = 40,
    shouldShowLarge = [false, false, true],
    containerSx,
    ...rest
  } = props

  const aspectRatio = 497 / 107

  return (
    <View sx={containerSx} {...rest}>
      <Image
        source={require('./beatgig-logo-256.png')}
        sx={{
          size,
          display: shouldShowLarge.map((showLarge) =>
            showLarge ? 'none' : 'flex'
          ),
          ...sx,
        }}
      />
      <Image
        source={require('./beatgig-256.png')}
        sx={{
          height: size,
          display: shouldShowLarge.map((showLarge) =>
            showLarge ? 'flex' : 'none'
          ),
          width: Array.isArray(size)
            ? size.map((height) => height * aspectRatio)
            : aspectRatio * size,
          ...sx,
        }}
      />
    </View>
  )
})

export default Logo
