import Analytics from '@beatgig/analytics/typez'
import { useCallback } from 'react'
import useAuthPopupState, { openAuthPopup } from './use-auth-popup-state'

export default function useOpenAuthPopup() {
  // const [, update] = useAuthPopupState()

  // return useCallback(() => {
  //   // Analytics.track('Open Auth Popup')
  //   update(true)
  // }, [update])
  return openAuthPopup
}
// hi
